import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Divider,
  Button,
  Grid,
  Tooltip,
  Box,
} from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';import { ComponentStyles } from "../styles";
import { calculateAuditDueDataData } from "../utils/lib";

const TwoLineCard = ({ title, nextAuditDate, inHowManyDays, textColor, tooltipText }) => {

  const auditDueData = calculateAuditDueDataData(nextAuditDate);

  return (
    <Card variant="outlined" style={ComponentStyles.twoLineThreeLineCard}>
      <CardContent style={{ padding: "16px 32px" }}>
        {title && (
          <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: "10px" }}>
            <Typography
              style={{
                fontSize: 18,
                fontWeight: 700,
                color: "#344054",
              }}
            >
              {title}
            </Typography>
            {tooltipText && (
              <Tooltip title={tooltipText} arrow placement="top">
                <InfoOutlinedIcon sx={{ fontSize: 18, ml: 1, color: "#98A2B3", cursor: "pointer" }} />
              </Tooltip>
            )}
          </Box>
        )}
        <Divider />
        <Grid container sx={{ mt: 2 }}>
          <Grid item xs={inHowManyDays ? 6 : 12}>
            {nextAuditDate && (
              <Typography
                style={{
                  color: textColor || "#344054", // Use textColor prop if provided, otherwise default to "#344054"
                  fontFamily: "Inter",
                  fontSize: "36px",
                  fontStyle: "normal",
                  fontWeight: "700",
                  letterSpacing: "-0.72px",
                }}
              >
                {nextAuditDate}
              </Typography>
            )}
          </Grid>
          {inHowManyDays && (
            <Grid item xs={6}>
              <Button
                sx={{
                  borderRadius: "8.534px",
                  border: "1.422px solid var(--Gray-300, #D0D5DD)",
                  background: "var(--Base-White, #FFF)",
                  textTransform: "none",
                  color: "#344054",
                  textAlign: "center",
                  fontSize: "19.913px",
                  fontWeight: 500,
                  padding: "16px 32px 32px 32px",
                }}
              >
                {nextAuditDate ? "In 302 days" : "No Next Audit Date"}
              </Button>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default TwoLineCard;

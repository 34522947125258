import React, { useState } from "react";
import { Typography, Grid, Paper } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAuditById } from "../../reducers/audit.reducer";
import { selectFactoryList } from "../../selectors/factory.selector";
import ListCard from "../listCard.component";
import { ComponentStyles } from "../../styles";

const FactoryAuditListCard = ({ selectAudits }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch();

  const selectFactories = useSelector(selectFactoryList);
  const factoryIdToFactoryName = selectFactories.reduce((acc, factory) => {
    acc[factory.factoryId] = factory.name;
    return acc;
  }, {});

  const handleNaivgationToAuditDetails = (factoryId, auditId) => {

    dispatch(
      getAuditById({
        supplierId: params.supplierId,
        factoryId: factoryId,
        auditId: auditId,
      })
    );
    navigate(`${location.pathname}/audit/${auditId}`);
  };

  return (
    <>
      {selectAudits?.length > 0 &&
        selectAudits.map((audit) => (
          <Paper
            elevation={1}
            sx={{
              py: 3,
              px: 4,
              my: 4,
              boxShadow: "none",
              height: "150px",
              overflowY: "hidden",
              borderRadius: "12px",
              border: "1px solid #EAECF0",
              background: "#FFF",
              boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
              cursor: "pointer",
            }}
            key={audit.auditId}
            onClick={() =>
              handleNaivgationToAuditDetails(audit.factoryId, audit.auditId)
            }
          >
            <Grid
              container
              spacing={0}
              sx={ComponentStyles.alignBoxItemsCenter}
            >
              <Grid item xs={1}>
                <Typography
                  sx={{
                    color: "#3538CD",
                    fontSize: "18px",
                    fontWeight: 600,
                  }}
                >
                  {audit.year}
                </Typography>
                {audit?.state && audit.state === "test" && (
                  <Typography color="red" sx={{ fontSize: "10px" }}>
                    Internal - Not Approved
                  </Typography>
                )}
              </Grid>
              <Grid item xs={11}>
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: 18,
                    color: "#000",
                    display: "inline-block",
                    mr: 2,
                    mb: 2,
                  }}
                >
                  {factoryIdToFactoryName[audit.factoryId]}
                </Typography>

                <ListCard data={audit} type="audit" />
              </Grid>
            </Grid>
          </Paper>
        ))}
    </>
  );
};

export default FactoryAuditListCard;

import React, { useEffect, useState } from "react";

import { getListOfAllAudits } from "../../reducers/audit.reducer";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAuditList,
  selectAuditListForFactory,
} from "../../selectors/audit.selector";
import {
  Box,
  Typography,
  Button,
  Modal,
  Card,
  CircularProgress,
} from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import AuditList from "../auditList.component";
import FactoryAuditList from "./factoryAuditHistoryListCard.component";
import FactoryAuditListCard from "./factoryAuditHistoryListCard.component";
import AuditDocumentDrop from "../AuditDetails/auditDocumentDrop.component";
import { selectEmail } from "../../selectors/login.selector";
import { checkInternalEmail } from "../../utils/lib";

const FacilityAuditList = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [open, setOpen] = useState(false);
  const [uploading, setUploading] = useState(false);

  const userEmail = useSelector(selectEmail);
  const isInternalUser = checkInternalEmail(userEmail);

  const refreshAuditList = () => {
    dispatch(
      getListOfAllAudits({
        limit: 1000,
        offset: 0,
        supplierId: params.supplierId,
      })
    );
  };

  useEffect(() => {
    if (selectAudits && selectAuditList.length === 0) {
      refreshAuditList();
    }
  }, [params.supplierId]);

  const selectAudits = useSelector((state) =>
    selectAuditListForFactory(state, params.factoryId)
  );


  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setUploading(true);
    setTimeout(() => {
      refreshAuditList();
      setUploading(false);
    }, 5000); // Wait for 5 seconds before refreshing the list
  };

  return (
    <div style={{ position: "relative", paddingBottom: "70px" }}>
      {uploading && (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
            position: "absolute",
            top: 10,
            left: "50%",
            transform: "translateX(-50%)",
            backgroundColor: "rgba(255, 255, 255, 0.9)",
            borderRadius: "8px",
            boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.2)",
            zIndex: 1,
          }}
        >
          <CircularProgress size={20} sx={{ marginRight: "8px" }} />
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: 14,
              color: "#8B8D97",
            }}
          >
            Uploading in progress
          </Typography>
        </Box>
      )}
      <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
        <Button
          sx={{
            mt: 2,
            fontSize: "14px",
            fontWeight: 600,
            color: "#344054",
            borderRadius: "8px",
            padding: "10px 14px",
            border: "1px solid var(--Gray-3000, #D0D5DD)",
            background: "var(--Base-White, #FFF)",
            boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
            justifyContent: "center",
            alignItems: "center",
            textTransform: "none",
          }}
          onClick={handleOpen}
          startIcon={<UploadFileIcon sx={{ color: "#667085" }} />}
        >
          Upload Audit
        </Button>
      </Box>
      {selectAudits.length === 0 ? (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "50px",
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: 16,
              color: "#8B8D97",
            }}
          >
            No audit results yet. If you have uploaded an audit, you will be
            notified once it is ready.
          </Typography>
        </Box>
      ) : (
        <FactoryAuditListCard selectAudits={selectAudits} />
      )}

      <Modal open={open} onClose={handleClose}>
        <Card
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 1000,
            p: 4,
            boxShadow:
              "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1)",
            border: "1px solid rgba(234, 236, 240, 1)",
            borderRadius: "12px",
            py: 2,
          }}
        >
          <AuditDocumentDrop
            organisationId={params.organisationId}
            supplierId={params.supplierId}
            factoryId={params.factoryId}
            onClose={handleClose}
            mode="process"
          />
        </Card>
      </Modal>
    </div>
  );
};

export default FacilityAuditList;

import React, { useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  Card,
  CardContent,
  Divider,
} from "@mui/material";
import { selectNotifications } from "../../selectors/misc.selector";
import { getNotifications } from "../../reducers/misc.reducer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  bgcolor: "background.paper",

  p: 4,
  borderRadius: 2,
};

const NotificationModal = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const notifications = useSelector(selectNotifications);

  const handleCardClick = (notification) => {
    if (
      notification.supplierId &&
      !notification.factoryId &&
      !notification.auditId
    ) {
      navigate(`/suppliers/${notification.supplierId}`);
    } else if (
      notification.supplierId &&
      notification.factoryId &&
      !notification.auditId
    ) {
      navigate(
        `/suppliers/${notification.supplierId}/factory/${notification.factoryId}`
      );
    } else if (
      notification.supplierId &&
      notification.factoryId &&
      notification.auditId
    ) {
      navigate(
        `/suppliers/${notification.supplierId}/factory/${notification.factoryId}/audit/${notification.auditId}`
      );
    }
  };

  useEffect(() => {
    dispatch(getNotifications());
  }, [open]);
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="notification-modal-title"
    >
      <Box sx={style}>
        <Typography
          id="notification-modal-title"
          variant="h6"
          component="h2"
          sx={{ mb: 2 }}
        >
          Supplier Notes
        </Typography>
        {notifications?.map((notification) => (
          <Card
            key={notification._id}
            sx={{ mb: 2, cursor: "pointer" }}
            onClick={() => handleCardClick(notification)}
          >
            <CardContent>
              <Typography
                variant="subtitle2"
                color="text.secondary"
                sx={{ mb: 1 }}
              >
                <strong>Supplier</strong>: {notification.supplierName}
                {notification.factoryName && (
                  <>
                    {" "}
                    | <strong>Factory</strong>: {notification.factoryName}
                  </>
                )}
                {notification.auditName &&
                  notification.auditName !== "null" && (
                    <>
                      {" "}
                      | <strong>Audit</strong>: {notification.auditName}
                      <br />
                    </>
                  )}
              </Typography>
              <Divider />
              <Typography sx={{ mt: 1, fontWeight: 600, fontSize: "16px" }}>
                {notification.subject}
              </Typography>
              <Typography sx={{ mt: 1, fontWeight: 400, fontSize: "14px" }}>
                {notification.text}
              </Typography>

              <Typography
                sx={{ mt: 1, fontSize: "8px", textAlign: "right" }}
                color="text.secondary"
              >
                {moment(notification.createdAt).format(
                  "HH:mm A, MMM. DD, YYYY"
                )}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </Box>
    </Modal>
  );
};

export default NotificationModal;

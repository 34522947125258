import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Divider,
  Tooltip,
  IconButton,
  Button,
} from "@mui/material";
import SortIcon from "@mui/icons-material/Sort";
import { selectFactoryIssuesData } from "../../selectors/factory.selector";
import { useSelector } from "react-redux";
import { ComponentStyles, FacilityStyles } from "../../styles";
import {
  selectAuditIssueCAPData,
} from "../../selectors/audit.selector";

const FacilityIssuesCard = ({latestAuditData}) => {

 
  const auditData = latestAuditData?.metadata?.capDetails;

  // Dynamically generate issueSummary based on auditData types
  const issueSummary = auditData.reduce((acc, issue) => {
    if (!acc.some(item => item.category === issue.type)) {
      acc.push({
        category: issue.type,
        extreme: 0,
        critical: 0,
        major: 0,
        moderate: 0,
        observation: 0
      });
    }
    return acc;
  }, []);

  // Calculate issue summary from auditData
  auditData.forEach(issue => {
    const categoryIndex = issueSummary.findIndex(item => item.category === issue.type);
    if (categoryIndex !== -1) {
      switch (issue.severity.toLowerCase()) {
        case 'extreme':
          issueSummary[categoryIndex].extreme++;
          break;
        case 'critical':
          issueSummary[categoryIndex].critical++;
          break;
        case 'major':
          issueSummary[categoryIndex].major++;
          break;
        case 'moderate':
          issueSummary[categoryIndex].moderate++;
          break;
        case 'observation':
          issueSummary[categoryIndex].observation++;
          break;
        default:
          break;
      }
    }
  });

  // Filter out categories with no issues
  const filteredIssueSummary = issueSummary.filter(category => 
    category.extreme > 0 || category.critical > 0 || category.major > 0 || category.moderate > 0 || category.observation > 0
  );

  // Calculate total counts
  const totalCounts = filteredIssueSummary.reduce((acc, category) => {
    acc.totalIssues += category.extreme + category.critical + category.major + category.moderate + category.observation;
    acc.totalExtremeIssues += category.extreme;
    acc.totalCriticalIssues += category.critical;
    acc.totalMajorIssues += category.major;
    acc.totalModerateIssues += category.moderate;
    acc.totalObservationIssues += category.observation;
    return acc;
  }, {
    totalIssues: 0,
    totalExtremeIssues: 0,
    totalCriticalIssues: 0,
    totalMajorIssues: 0,
    totalModerateIssues: 0,
    totalObservationIssues: 0
  });

  return (
    <Paper elevation={1} sx={FacilityStyles.issuesTablePaper}>
       <Typography
        sx={{
          fontSize: 18,
          fontWeight: 700,
          color: "#344054",
          marginBottom: "10px",
        }}
      >
        {auditData?.auditor}
      </Typography>
      <Typography
        sx={{
          fontSize: 18,
          fontWeight: 700,
          color: "#344054",
          marginBottom: "10px",
        }}
      >
        Findings
      </Typography>
      <Divider sx={{ my: "12px" }} />
      <Box sx={{ height: 282, overflow: "auto" }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow >
              <TableCell
                sx={{
                  ...FacilityStyles.issuesTableCell,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "90px", // Fixed width
                  }}
                >
                  <Box sx={ComponentStyles.alignBoxItemsCenter}>
                    <Typography></Typography>
                  </Box>
                  <Box></Box>
                </Box>
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 500,
                  fontSize: 16,
                  borderBottom: "2px solid #3538CD",
                  borderRight: "2px dotted #3538CD",
                  pb: 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography sx={ComponentStyles.fieldName}>
                      Total
                    </Typography>
                  </Box>
                  <Box>{totalCounts.totalIssues}</Box>
                </Box>
              </TableCell>
              <TableCell
                sx={{
                  ...FacilityStyles.issuesTableCell,
                  color: "#B42318",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span>Extreme</span>
                  <Box>{totalCounts.totalExtremeIssues}</Box>
                </Box>
              </TableCell>
              <TableCell
                sx={{
                  ...FacilityStyles.issuesTableCell,
                  color: "#FF692E",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span>Critical</span>
                  <Box>{totalCounts.totalCriticalIssues}</Box>
                </Box>
              </TableCell>
              <TableCell
                sx={{
                  ...FacilityStyles.issuesTableCell,
                  color: "#EAAA08",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span>Major</span>
                  <Box>{totalCounts.totalMajorIssues}</Box>
                </Box>
              </TableCell>
              <TableCell
                sx={{
                  ...FacilityStyles.issuesTableCell,
                  color: "#667085",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span>Moderate</span>
                  <Box>{totalCounts.totalModerateIssues}</Box>
                </Box>
              </TableCell>
              <TableCell
                sx={{
                  ...FacilityStyles.issuesTableCell,
                  color: "#667085",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span>Observation</span>
                  <Box>{totalCounts.totalObservationIssues}</Box>
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {issueSummary.map((issue, index) => {
              const issueCount =
                issue.extreme + issue.critical + issue.major + issue.moderate + issue.observation;
              return issueCount > 0 ? (
                <TableRow key={index} >
                  <TableCell
                    sx={{
                      fontWeight: 500,
                      fontSize: 16,
                      color: "#45464E",
                      width: "90px", // Fixed width
                      pr: 0,
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "normal",
                 
                      borderBottom: "none",
                      pb: 0.5,
                    }}
                  >
  
                    {issue.category.length > 20 ? (
                      <span>{issue.category}</span>
                    ) : (
                      issue.category
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: 500,
                      fontSize: 16,
                      color: issueCount ? "#45464E" : "#D0D5DD",
                      borderBottom: "none",
                      borderRight: "2px dotted #3538CD",
                      pb: 0.5,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {issueCount}
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: 500,
                      fontSize: 16,
                      color: issue.extreme ? "#45464E" : "#D0D5DD",
                      borderBottom: "none",
                      pb: 0.5,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {issue.extreme}
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: 500,
                      fontSize: 16,
                      color: issue.critical ? "#45464E" : "#D0D5DD",
                      borderBottom: "none",
                      pb: 0.5,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {issue.critical}
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: 500,
                      fontSize: 16,
                      color: issue.major ? "#45464E" : "#D0D5DD",
                      borderBottom: "none",
                      pb: 0.5,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {issue.major}
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: 500,
                      fontSize: 16,
                      color: issue.moderate ? "#45464E" : "#D0D5DD",
                      borderBottom: "none",
                      pb: 0.5,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {issue.moderate}
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: 500,
                      fontSize: 16,
                      color: issue.observation ? "#45464E" : "#D0D5DD",
                      borderBottom: "none",
                      pb: 0.5,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {issue.observation}
                    </Box>
                  </TableCell>
                </TableRow>
              ) : null;
            })}
          </TableBody>
        </Table>
      </Box>
    </Paper>
  );
};

export default FacilityIssuesCard;
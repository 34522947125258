import { useState, useEffect } from 'react';
import { Grid, TextField, Button, Box, AppBar, Autocomplete } from '@mui/material';
import {
    Toolbar,
    Typography,
    IconButton,
} from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSelector, useDispatch } from 'react-redux';
import { selectSupplierList } from '../selectors/supplier.selector';
import { getListOfAllSuppliers } from '../reducers/supplier.reducer';
import { createFactory } from '../reducers/factory.reducer';


const CreateFacilityForm = ({ handleDrawerClose }) => {
    const dispatch = useDispatch();
    const [formValues, setFormValues] = useState({});
    const suppliers = useSelector(selectSupplierList);

    useEffect(() => {
        if (!suppliers || suppliers.length === 0) {
            dispatch(getListOfAllSuppliers({
                limit: 999,
                offset: 0
            }));
        }
    }, [dispatch]);

    const fields = {
        required: {
            title: "Required General CMA Information",
            fields: [
                {
                    name: "supplierId",
                    label: "Associated Supplier",
                    type: "autocomplete",
                    required: true,
                    options: suppliers.map(supplier => ({
                        value: supplier.supplierId,
                        label: supplier.name
                    })),
                    layout: { xs: 12, sm: 6 }
                },
                {
                    name: "name",
                    label: "CMA Name",
                    type: "text",
                    required: true,
                    layout: { xs: 12, sm: 6, rowBreak: true }
                },
            ]
        },
        optional: {
            general: {
                title: "Optional General CMA Information",
                fields: [
                    {
                        name: "status",
                        label: "Status",
                        type: "select",
                        options: ["active", "inactive"],
                        layout: { xs: 12, sm: 4 }
                    },
                    {
                        name: "tier",
                        label: "Tier",
                        type: "select",
                        options: [1, 2, 3],
                        layout: { xs: 12, sm: 4 }
                    },
                    {
                        name: "privateLabel",
                        label: "Private Label",
                        type: "select",
                        options: ["Yes", "No"],
                        layout: { xs: 12, sm: 4 }
                    },
                    {
                        name: "address",
                        label: "Site Address",
                        type: "text",
                        layout: { xs: 12, sm: 12, rowBreak: true }
                    },
                    {
                        name: "country",
                        label: "Country",
                        type: "text",
                        layout: { xs: 12, sm: 4 }
                    },
                    {
                        name: "state",
                        label: "State",
                        type: "text",
                        layout: { xs: 12, sm: 4 }
                    },
                    {
                        name: "city",
                        label: "City",
                        type: "text",
                        layout: { xs: 12, sm: 4, rowBreak: true }
                    },
                    {
                        name: "associatedAddress",
                        label: "Associated Addresses",
                        type: "text",
                        layout: { xs: 12, sm: 12, rowBreak: true }
                    },
                    {
                        name: "alias",
                        label: "Site Alias",
                        type: "text",
                        layout: { xs: 12, sm: 6 }
                    },
                    {
                        name: "factoryId",
                        label: "Site ID",
                        type: "text",
                        layout: { xs: 12, sm: 6, rowBreak: true }
                    },
                    {
                        name: "contactPerson.name",
                        label: "Contact Name",
                        type: "text",
                        layout: { xs: 12, sm: 4 }
                    },
                    {
                        name: "contactPerson.position",
                        label: "Contact Position",
                        type: "text",
                        layout: { xs: 12, sm: 4 }
                    },
                    {
                        name: "contactPerson.email",
                        label: "Contact Email",
                        type: "email",
                        layout: { xs: 12, sm: 4, rowBreak: true }
                    },
                    {
                        name: "contactPerson.phone",
                        label: "Contact Phone",
                        type: "text",
                        layout: { xs: 12, sm: 4 }
                    }
                ]
            },
            confidential: {
                title: "Optional Confidential Business Information",
                fields: [
                    {
                        name: "productCategories",
                        label: "Product Categories",
                        type: "text",
                        layout: { xs: 12, sm: 6 }
                    },
                    {
                        name: "productProduced",
                        label: "Products Produced",
                        type: "text",
                        layout: { xs: 12, sm: 6, rowBreak: true }
                    },
                    {
                        name: "spend",
                        label: "Spend",
                        type: "text",
                        layout: { xs: 12, sm: 6 }
                    },
                ]
            }
        }
    };

    const handleInputChange = (fieldName) => (event) => {
        setFormValues({
            ...formValues,
            [fieldName]: event.target.value
        });
    };

    const handleSupplierChange = (event, newValue) => {
        setFormValues({
            ...formValues,
            supplierId: newValue?.value,
            supplierName: newValue?.label
        });
    };

    const handleDateChange = (fieldName) => (date) => {
        setFormValues(prev => ({
            ...prev,
            [fieldName]: date ? date.format('YYYY-MM-DD') : null
        }));
    };

    const handleSubmit = () => {
        // Check required fields
        const requiredFields = fields.required.fields;
        const missingFields = requiredFields.filter(field => !formValues[field.name]);
        
        if (missingFields.length > 0) {
            // You can use your preferred notification system here
            alert('Please fill out all required fields: ' + missingFields.map(f => f.label).join(', '));
            return;
        }

        dispatch(createFactory(formValues));
        handleDrawerClose();
    };

    const renderFormFields = () => {
        const renderSection = (fields, title) => {
            let currentRow = [];
            const rows = [];

            fields.forEach((field) => {
                currentRow.push(field);
                if (field.layout?.rowBreak) {
                    rows.push([...currentRow]);
                    currentRow = [];
                }
            });

            if (currentRow.length > 0) {
                rows.push(currentRow);
            }

            return (
                <>
                    <Grid item xs={12}>
                        <Typography variant="h6" sx={{ mt: 3, mb: 2, fontSize: 16, fontWeight: 600 }}>
                            {title}
                        </Typography>
                    </Grid>
                    {rows.map((row, rowIndex) => (
                        <Grid container item spacing={2} key={`row-${rowIndex}`}>
                            {row.map((field) => (
                                <Grid
                                    item
                                    xs={field.layout?.xs || 12}
                                    sm={field.layout?.sm || 4}
                                    key={field.name}
                                >
                                    {field.type === 'autocomplete' ? (
                                        <Autocomplete
                                            fullWidth
                                            options={field.options}
                                            getOptionLabel={(option) => option.label || ''}
                                            onChange={handleSupplierChange}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={field.label}
                                                    required={field.required}
                                                    variant="outlined"
                                                />
                                            )}
                                            value={formValues[field.name] ? {
                                                value: formValues.supplierId,
                                                label: formValues.supplierName
                                            } : null}
                                            isOptionEqualToValue={(option, value) =>
                                                option.value === value.value
                                            }
                                        />
                                    ) : field.type === 'date' ? (
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <DatePicker
                                                label={field.label}
                                                value={formValues[field.name] ? moment(formValues[field.name]) : null}
                                                onChange={handleDateChange(field.name)}
                                                slotProps={{
                                                    textField: {
                                                        fullWidth: true,
                                                        required: field.required,
                                                        variant: "outlined",
                                                        sx: {
                                                            width: '100%',
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    borderRadius: '8px',
                                                                }
                                                            }
                                                        }
                                                    }
                                                }}
                                            />
                                        </LocalizationProvider>
                                    ) : field.type === 'select' ? (
                                        <TextField
                                            select
                                            fullWidth
                                            label={field.label}
                                            value={formValues[field.name] || ''}
                                            onChange={handleInputChange(field.name)}
                                            required={field.required}
                                            variant="outlined"
                                            SelectProps={{
                                                native: true,
                                            }}
                                        >
                                            <option value=""></option>
                                            {field.options.map((option) => (
                                                <option key={option} value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </TextField>
                                    ) : (
                                        <TextField
                                            fullWidth
                                            label={field.label}
                                            type={field.type}
                                            value={formValues[field.name] || ''}
                                            onChange={handleInputChange(field.name)}
                                            required={field.required}
                                            disabled={field.disabled}
                                            variant="outlined"
                                            multiline={field.type === 'textarea'}
                                            rows={field.type === 'textarea' ? 4 : 1}
                                        />
                                    )}
                                </Grid>
                            ))}
                        </Grid>
                    ))}
                </>
            );
        };

        return (
            <>
                {renderSection(fields.required.fields, fields.required.title)}
                {renderSection(fields.optional.general.fields, fields.optional.general.title)}
                {/* {renderSection(fields.optional.confidential.fields, fields.optional.confidential.title)} */}
            </>
        );
    };

    return (
        <Grid container sx={{ padding: 3 }}>
            <Box sx={{ width: "100%", position: "sticky", top: 0, zIndex: 1000, mb: 2 }}>
                <AppBar
                    color="inherit"
                    position="static"
                    sx={{
                        textAlign: "center",
                        fontSize: "16px",
                        fontWeight: 500,
                        borderBottom: "1px solid #E0E0E0",
                    }}
                    elevation={0}
                >
                    <Toolbar>
                        <Grid container alignItems="center">
                            <Grid item xs={4}>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    aria-label="back"
                                    sx={{ alignItems: "flex-start", display: "flex" }}
                                    onClick={handleDrawerClose}
                                >
                                    <ArrowBackIcon />
                                </IconButton>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography
                                    sx={{
                                        flexGrow: 1,
                                        textAlign: "center",
                                        paddingTop: "15px",
                                        fontSize: "16px",
                                        fontWeight: 500,
                                    }}
                                >
                                    Create New Site
                                </Typography>
                            </Grid>
                            <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSubmit}
                                    sx={{
                                        backgroundColor: "#6172F3",
                                        color: "white",
                                        "&:hover": {
                                            backgroundColor: "#5667e2",
                                            fontWeight: "bold",
                                        },
                                        textTransform: "none",
                                        minWidth: "50px",
                                        height: "40px",
                                        borderRadius: "8px",
                                    }}
                                >
                                    Create
                                </Button>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </Box>

            

            <Grid 
                container 
                spacing={3} 
                sx={{ 
                    maxWidth: "700px",
                    margin: "0 auto",
                }}
            >
                {renderFormFields()}
            </Grid>
        </Grid>
    );
};

export default CreateFacilityForm;
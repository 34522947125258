import React from "react";
import { Box, Grid, Typography, Drawer, Divider } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { selectSupplierNameFromId } from "../../selectors/supplier.selector";
import { useSelector } from "react-redux";
import { ComponentStyles, FacilityStyles } from "../../styles";
import FacilityTierMap from "./facilityTierMap.component";

const FacilityInformationDrawer = ({
  factoryData,
  isDrawerOpen,
  toggleDrawer,
}) => {
  const params = useParams();
  const supplierName = useSelector((state) =>
    selectSupplierNameFromId(state, params?.supplierId)
  );

  const capitalizeWords = (str) => {
    try {
      return str
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(" ");
    } catch (error) {
      console.error(
        "Error in capitalizeWords function in facilityInformation.component.js:",
        error
      );
      return str;
    }
  };

  return (
    <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer(false)}>
      <Box
        sx={{ width: 787.5, padding: 4, paddingLeft: 6, paddingRight: 6 }}
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <Typography variant="h6" sx={{ mb: 2 }}>
          Factory General Information
        </Typography>

        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12}>
            <Typography sx={ComponentStyles.fieldName}>
              Factory Address
            </Typography>
            <Typography
              sx={{
                ...ComponentStyles.fieldValue,
                wordBreak: "break-word",
              }}
            >
              {factoryData?.metadata?.address ? capitalizeWords(factoryData.metadata.address) : "-"}
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography sx={ComponentStyles.fieldName}>Contact Person</Typography>
            <Typography
              sx={{
                ...ComponentStyles.fieldValue,
                wordBreak: "break-word",
              }}
            >
              {factoryData?.metadata?.contactPerson?.name || "-"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={ComponentStyles.fieldName}>Position</Typography>
            <Typography
              sx={{
                ...ComponentStyles.fieldValue,
                wordBreak: "break-word",
              }}
            >
              {factoryData?.metadata?.contactPerson?.position || "-"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={ComponentStyles.fieldName}>Phone</Typography>
            <Typography
              sx={{
                ...ComponentStyles.fieldValue,
                wordBreak: "break-word",
              }}
            >
              {factoryData?.metadata?.contactPerson?.phone || "-"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={ComponentStyles.fieldName}>Email</Typography>
            <Typography
              sx={{
                ...ComponentStyles.fieldValue,
                wordBreak: "break-word",
                overflowWrap: "break-word",
              }}
            >
              {factoryData?.metadata?.contactPerson?.email || "-"}
            </Typography>
          </Grid>
        </Grid>

        <Divider sx={{ mt: 2, mb: 2 }} />

        <Grid container spacing={2} sx={{ mb: 3 }}>
          <Grid item xs={6}>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 16,
                color: "#8B8D97",
              }}
            >
              Product Support
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 16,
                color: "#45464E",
              }}
            >
              {factoryData?.metadata?.productSupport?.name || "-"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 16,
                color: "#8B8D97",
              }}
            >
              PS email
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 16,
                color: "#45464E",
              }}
            >
              {factoryData?.metadata?.productSupport?.email || "-"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 16,
                color: "#8B8D97",
              }}
            >
              Business Unit
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 16,
                color: "#45464E",
              }}
            >
              {factoryData?.businessUnit || "-"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 16,
                color: "#8B8D97",
              }}
            >
              TMLs
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 16,
                color: "#45464E",
              }}
            >
              {factoryData?.metadata?.tmls || "-"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 16,
                color: "#8B8D97",
              }}
            >
              Influence
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 16,
                color: "#45464E",
              }}
            >
              {factoryData?.metadata?.influence || "-"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 16,
                color: "#8B8D97",
              }}
            >
              Shared VBU
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 16,
                color: "#45464E",
              }}
            >
              {factoryData?.metadata?.sharedVBU || "-"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 16,
                color: "#8B8D97",
              }}
            >
              Agreement Type
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 16,
                color: "#45464E",
              }}
            >
              {factoryData?.metadata?.agreementType || "-"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 16,
                color: "#8B8D97",
              }}
            >
              CMA connection (for subcontractor)
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 16,
                color: "#45464E",
              }}
            >
              {factoryData?.metadata?.cmaConnection || "-"}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Drawer>
  );
};

export default FacilityInformationDrawer;
